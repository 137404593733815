
const findItemById = (data, id , key)=> {
    let item_id = key? data[key] : data.id
    if (item_id == id) {
        return data;
    } else if (data.children) {
        for (let i = 0; i < data.children.length; i++) {
            const result = findItemById(data.children[i], id);
            if (result) {
                return result;
            }
        }
    }
    return false;
}
export default findItemById