
   
import router from '../router'
import limitJudge from '@/util/includes'
const logout = () =>{
    
    if(localStorage.getItem("user")){
        const user = JSON.parse(decodeURIComponent(window.atob(localStorage.getItem("user"))))
        const signList = JSON.parse(decodeURIComponent(window.atob(localStorage.getItem("permissions"))))
        if(window.socket1){
          //账号审核相关
          if(limitJudge(signList, 'system:role:platform')){
            console.log('111')
            window.socket2.disconnect()
          }
          // 订单相关
          // if(this.signList.includes('system:role:recycle')){
            window.socket1.disconnect()
          // }

        }

    }
    localStorage.removeItem('deptid')
    localStorage.removeItem('token')
    localStorage.removeItem('nickName')
    localStorage.removeItem('userId')
    localStorage.removeItem('personType')
    localStorage.removeItem('id')
    localStorage.removeItem('user')
    router.push('/page')
}
 export default logout