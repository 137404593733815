<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>

import WebSocketService from './util/websocket/webSockets';
export default {
  data () {
    return {

    }
  },
  mounted () {
    console.log('欢迎来到物客管理平台')
    window.addEventListener('beforeunload', this.beforeunloadHandler)
    /*
    这里需要注意一下，在vue里我们调用methods的方法经常使用的是this.clickFun(),
    但是在这里不需要括号，这里不需要括号，这里不需要括号
  */
    if(localStorage.getItem("user")){
      const user = JSON.parse(decodeURIComponent(window.atob(localStorage.getItem("user"))))
      if(user.tenantIds.length > 0){
        user.tenantIds.forEach(item => {
          window[`websocketService${item}1`] = new WebSocketService(item,1)
        });

      }

    }
  },
  destroyed () {
    // 销毁监听事件
    window.removeEventListener('beforeunload', this.beforeunloadHandler)
  },
  methods: {
    beforeunloadHandler (e) {
      console.log(e, '刷新或关闭会触发  但不知道是属于刷新还是关闭')
      e = e || window.event

      // 兼容IE8和Firefox 4之前的版本
      if (e) {
        e.returnValue = '关闭提示'
      }

      // Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
      return '关闭提示'
    }
  },
  methods: {

  }
}
</script>

<style  lang="scss">
  @import url('../src/assets/css/uflex.css');
  * {
    margin: 0;
    padding: 0;
  }
  .major_button{
    min-width: 100px;
    height: 32px;
  }
  .bottom_btn{
    width: auto !important;
    min-width: 100px;
    height: 32px !important;
  }
  .major_button.add{
    color: #016EF1 !important;
    border: 1px solid #016EF1 !important;
  }
  .bottom_btn.primary_line{
    border: 1px solid #016EF1 !important;
    color: #016EF1 !important;
    
  }

  .from_input_box {
    width: 220px !important;
  }
  .pop_btn{
    min-width: 100px;
  }
  .pop_btn.submit,.primary{
    border-color: #016EF1 !important;
    background: #016EF1 !important;
    color: #fff !important;
  }
  .el-dialog__title{
    font-weight: bold;
  }
  .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf {
    border-top: none !important;
    border-bottom: none !important;
  }


  // 全局css 加上以下代码，可以隐藏上下箭头
  
  // 取消input的上下箭头
  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  
  }
  
  input::-webkit-outer-spin-button{
    -webkit-appearance: none !important;
  
  }
  
  input[type="number"]{
    -moz-appearance: textfield;
  }
  .mgr-5{
    margin-right: 5px;
  }
  .mgr-10{
    margin-right: 10px;
  }
  .mgr-20{
    margin-right: 20px;
  }
  .mgr-40{
    margin-right: 40px;
  }
  .mgr-80{
    margin-right: 80px;
  }
  .mgr-100{
    margin-right: 100px;
  }
  .mgt-5{
    margin-top: 5px;
  }
  .mgt-8{
    margin-top: 8px;
  }
  .mgt-10{
    margin-top: 10px;
  }
  .mgt-15{
    margin-top: 15px;
  }
  .mgt-20{
    margin-top: 20px;
  }
  .mgt-30{
    margin-top: 30px;
  }
  .mgt-40{
    margin-top: 40px;
  }
  .mgb-10{
    margin-bottom: 10px;
  }
  .mgb-15{
    margin-bottom: 15px;
  }
  .mgb-20{
    margin-bottom: 20px;
  }
  .pdl-10{
    padding-left: 10px;
  }
  .pdb-20{
    padding-bottom: 20px;
  }
  .text-12{
    font-size: 12px;
  }
  .line_1{
    min-width: 0; // 防止其他元素被挤压
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1; // 限制的文字行数
  }
  .line_2{
    min-width: 0; // 防止其他元素被挤压
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; // 限制的文字行数
  }
  .line_3{
    min-width: 0; // 防止其他元素被挤压bottom_btn
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3; // 限制的文字行数
  }
  .text-b{
    font-weight: bold;
  }
  .rel{
    position: relative;
  }
  .common_form_title{
    width: 70px;
    margin-right: 10px;
  }
  .text-016EF1{
    color: #016EF1 !important;
  }
  .text-333{
    color: #333 !important;
  }
  .op-03{
      opacity: .3;
  }
  ::v-deep .el-dialog{
    border: 5px;
  }
  .el-dialog__title{
    font-size: 16px !important;
  }
  .el-button{
    border-radius: 5px !important;
  }
  /* 定义滚动条整体的样式 */
  ::-webkit-scrollbar {
      width: 12px; /* 滚动条宽度 */
      height: 12px; /* 对于垂直滚动条的高度 */
  }

  /* 滚动条轨道 */
  ::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0.01); /* 轨道颜色 */
      border-radius: 6px; /* 轨道圆角 */
  }

  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.1); /* 滑块颜色 */
      border-radius: 6px; /* 滑块圆角 */
      border: 2px solid transparent; /* 可选：滑块边框 */
      background-clip: content-box; /* 保持边框透明，仅适用于滑块 */
  }

</style>
