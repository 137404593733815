/**
 * 环境 dev 测试API  pro线上API
 */
// export const ENV = 'dev'
export const ENV = 'pro'

/**
 * 本地API地址
 */

export const SITE_URL_LOCAL = {
	// API: 'http://192.168.1.39:8999',
	API: 'http://192.168.1.59:8999',
	WS: 'ws://192.168.1.59:8999/webSocket'
}

/**
 * 在线API地址
 */
export const SITE_URL_ONLINE = {
	API: 'https://genqian.top/test/prod-api',
	WS: 'wss://www.urlk.com/webSocket'
}

/**
 * 版本号
 */
export const VIESION = '/v1'

/**
 * 项目名称
 */
export const APPNAME = ''

/**
 * WS请求地址
 */
export const SITE_URL_WS = (ENV === 'dev' ? SITE_URL_LOCAL.WS : SITE_URL_ONLINE.WS)

/**
 * API请求地址
 */
export const SITE_URL_API = (ENV === 'dev' ? SITE_URL_LOCAL.API : SITE_URL_ONLINE.API)

export const FILE_SIZE = 20