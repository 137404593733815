// WebSocketService.js
import { SITE_URL_WS } from '../../config/site'

export default class WebSocketService {
    constructor(id, type) {
      this.url = `${SITE_URL_WS}/${id}/${type}`;
      this.websocket = null;
      this.isConnected = false;
      this.messageCallback = null; // 添加消息回调函数
      
      this.connect();
    }
  
    connect() {
      if(this.isConnected){
        console.log('WebSocket已连接');
        return false;
      }
      this.websocket = new WebSocket(this.url);
      
      this.websocket.onopen = () => {
        console.log('Connected to WebSocket');
        this.isConnected = true;
      };
      
      this.websocket.onmessage = (event) => {
        console.log('Received message:', event.data);
        // this.messageCallback(event.data);
      };
      
      this.websocket.onerror = (error) => {
        console.error('WebSocket Error:', error);
        // this.reconnect();
      };
      
      this.websocket.onclose = () => {
        console.log('WebSocket Connection closed');
        this.isConnected = false;
        // this.reconnect();
      };
    }

    // 新增 disconnect 方法用于关闭 WebSocket 连接
    disconnect() {
      if (this.websocket && this.isConnected) {
        this.websocket.close();
        console.log('WebSocket connection closed by disconnect method');
        this.isConnected = false;
      } else {
        console.log('WebSocket is already disconnected or not initialized');
      }
    }
  
    send(message) {
      if (this.isConnected) {
        this.websocket.send(message);
      } else {
        console.log('Not connected, message not sent');
      }
    }
  
    // 修改原有的 onclose 方法为一个事件处理器，或者您可以考虑移除它，直接使用 disconnect 方法
    // 如果需要保留作为事件处理器，请确保在适当的地方绑定和触发
    // onclose() {
    //   // 逻辑可以根据需求调整，比如这里可以触发重新连接逻辑
    // }
  
    reconnect() {
      setTimeout(() => {
        console.log('Reconnecting to WebSocket...');
        this.connect();
      }, 5000); // 5秒延迟后重连
    }
}