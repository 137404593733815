import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import {apiRequest} from '@/api/index' 

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'home',
		redirect: '/page',
		component: HomeView,
	},
	{
		path: '/HomeView',
		name: 'HomeView',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "about" */ '../views/HomeView.vue'),
		children: [
			{
				path: '/Area',
				name: 'Area',
				component: () => import('../views/page/Area.vue'),
			},
			{
				path: '/Area/Notice',
				name: 'Notice',
				component: () => import('../views/page/Notice.vue'),
			},
			{
				path: '/Access',
				name: 'Access',
				component: () => import('../views/goods/Access.vue'),
			},
			{
				path: '/Access/DetailPages',
				name: 'DetailPages',
				component: () => import('../views/goods/DetailPages.vue'),
			},
			{
				path: '/librarys/Warehousing',
				name: 'Warehousing',
				component: () => import('../views/librarys/Warehousing.vue'),
			},
			{
				path: '/librarys/Outbound',
				name: 'Outbound',
				component: () => import('../views/librarys/Outbound.vue'),
			},
			{
				path: '/Personnel',
				name: 'Personnel',
				component: () => import('../views/personnel/Personnel.vue'),
			},
			{
				path: '/Data/order',
				name: 'Data',
				component: () => import('../views/datas/Data.vue'),
			},
			{
				path: '/librarys/Inbound',
				name: 'Inbound',
				component: () => import('../views/librarys/Inbound.vue'),
			},
			{
				path: '/librarys/statistics',
				name: 'statistics',
				component: () => import('../views/librarys/statistics.vue'),
			},
			{
				path: '/librarys/Stock',
				name: 'Stock',
				component: () => import('../views/librarys/Stock.vue'),
			},
			{
				path: '/Data/exportPage',
				name: 'exportPage',
				component: () => import('../views/datas/exportPage.vue'),
			},
			{
				path: '/Data/analysis',
				name: 'analysis',
				component: () => import('../views/datas/analysis.vue'),
			},
			{
				path: '/Area/Enter',
				name: 'Enter',
				component: () => import('../views/goods/Enter.vue'),
			},
			{
				path: '/recycler/Enter',
				name: 'Enter',
				component: () => import('../views/goods/Enter.vue'),
			},
			{
				path: '/recyclerOrder/Enter',
				name: 'Enter',
				component: () => import('../views/goods/Enter.vue'),
			},
			{
				path: '/vehicle',
				name: 'vehicle',
				component: () => import('../views/vehicle/index.vue'),
			},
			{
				path: '/system/user',
				name: 'user',
				component: () => import('../views/system/user.vue'),
			},
			{
				path: '/system/audit',
				name: 'audit',
				component: () => import('../views/system/audit.vue'),
			},
			{
				path: '/system/auditDetail',
				name: 'auditDetail',
				component: () => import('../views/system/auditDetail.vue'),
			},
			{
				path: '/roleIndex',
				name: 'roleIndex',
				component: () =>
				import('../views/system/roleIndex.vue'),
				children: [
					{
						path: '/system/role',
						name: 'role',
						component: () => import('../views/system/role.vue'),
					},
					{
						path: '/system/authority',
						name: 'authority',
						component: () => import('../views/system/authority.vue'),
					},

				]
			},
			{
				path: '/recycler',
				name: 'recycler',
				component: () => import('../views/recycler/recycler.vue'),
			},
			{
				path: '/recycler/order',
				name: 'recyclerOrder',
				component: () => import('../views/recycler/recyclerOrder.vue'),
			},
			{
				path: '/recyclerOrder',
				name: 'recyclerOrder',
				component: () => import('../views/recycler/recyclerOrder.vue'),
			},
			{
				path: '/recycler/detail',
				name: 'recyclerDetail',
				component: () => import('../views/recycler/orderDetail.vue'),
			},
			{
				path: '/recyclerOrder/Detail',
				name: 'recyclerDetail',
				component: () => import('../views/recycler/orderDetail.vue'),
			},
			{
				path: '/recyclerMap',
				name: 'recyclerMap',
				component: () => import('../views/recycler/map.vue'),
			},
			{
				path: '/contract',
				name: 'contract',
				component: () => import('../views/contract/contract.vue'),
			},
			{
				path: '/message',
				name: 'message',
				component: () => import('../views/message/message.vue'),
			},
			{
				path: '/equipment',
				name: 'equipment',
				component: () => import('../views/equipment/equipment.vue'),
			},
			{
				path: '/equipment/detail',
				name: 'equipmentDetail',
				component: () => import('../views/equipment/equipmentDetail.vue'),
			},
			{
				path: '/billContract/bill',
				name: 'bill',
				component: () => import('../views/billContract/bill.vue'),
			},
			{
				path: '/billcontract/contract',
				name: 'billContract',
				component: () => import('../views/billContract/contract.vue'),
			},
		],
	},
	{
		path: '/page',
		name: 'page',
		component: () => import('../views/logon/page.vue'),
	},
	{
		path: '/register',
		name: 'register',
		component: () => import('../views/logon/register.vue'),
	},
	{
		path: '/registerHss',
		name: 'registerhss',
		component: () => import('../views/logon/register_hss.vue'),
	},
	{
		path: '/registerGs',
		name: 'registerGs',
		component: () => import('../views/logon/register_gs.vue'),
	},
	{
		path: '/editPassword',
		name: 'editPassword',
		component: () => import('../views/logon/editPassword.vue'),
	},
	{
		path: '/determine',
		name: 'determine',
		component: () => import('../views/determine/determine.vue'),
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
})

// 导航守卫：使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登陆
router.beforeEach((to, from, next) => {
	if (to.path === '/page') {
	  next();
	} else {

	  let token = localStorage.getItem("token");
   
	  if (!token && !to.path.includes("register") && !to.path.includes("editPassword") && !to.path.includes("determine")) {
		console.log('11')
		next('/page');
	  } else {
		next();
	  }
	}
  });


// 避免了到当前位置的冗余导航
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err)
}

export default router
